var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cp-error" },
    [
      _c("img", { attrs: { src: _vm.error.cover, alt: _vm.error.type } }),
      _c("p", { staticClass: "cp-error__title" }, [
        _vm._v(" " + _vm._s(_vm.error.title) + " "),
      ]),
      _vm.error.info
        ? _c("p", { staticClass: "cp-error__info" }, [
            _vm._v(" " + _vm._s(_vm.error.info) + " "),
          ])
        : _vm._e(),
      _c(
        "p",
        { staticClass: "cp-error__refresh", on: { click: _vm.refreshPage } },
        [
          _c("span", { staticClass: "cp-icon" }, [_vm._v("")]),
          _c("span", { staticClass: "text" }, [_vm._v("点击刷新")]),
        ]
      ),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.returnIndex } },
        [_vm._v(" 返回首页 ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }