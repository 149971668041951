<!--
 * @Descripttion: 错误页面[403-禁止访问、404-未找到页面、500-服务器错误]访问地址通过参数区分：error?type=403、error?type=404、error?type=500
 * @Author: koujing
 * @Date: 2021-03-11 16:42:57
 * @LastEditors: koujing
 * @LastEditTime: 2021-03-11 17:52:32
-->
<template>
  <div class="cp-error">
    <img
      :src="error.cover"
      :alt="error.type">
    <p class="cp-error__title">
      {{ error.title }}
    </p>
    <p
      v-if="error.info"
      class="cp-error__info">
      {{ error.info }}
    </p>
    <p
      class="cp-error__refresh"
      @click="refreshPage">
      <span class="cp-icon">&#xea7d;</span>
      <span class="text">点击刷新</span>
    </p>
    <el-button
      type="primary"
      @click="returnIndex">
      返回首页
    </el-button>
  </div>
</template>

<script>
import error403Cover from '@/assets/images/error/403.png';
import error404Cover from '@/assets/images/error/404.png';
import error500Cover from '@/assets/images/error/500.png';

export default {
  inject: ['reload'],
  name: 'CpError',
  props: {
    indexRouter: {
      type: String,
      default: '/index'
    }
  },
  data() {
    return {
      error: {
        type: 404,
        title: '非常抱歉，您访问的页面不存在',
        info: '',
        cover: error404Cover
      }
    };
  },
  created() {
    const errorCode = this.$route.params.errorCode;
    switch (errorCode) {
      case '403':
        this.error = {
          type: 403,
          title: '非常抱歉，您无权访问该页面',
          info: '',
          cover: error403Cover
        };
        break;
      case '404':
        this.error = {
          type: 404,
          title: '非常抱歉，您访问的页面不存在',
          info: '',
          cover: error404Cover
        };
        break;
      case '500':
        this.error = {
          type: 500,
          title: '非常抱歉，服务器出错了',
          info: '',
          cover: error500Cover
        };
        break;
      default:
        break;
    }
  },
  methods: {
    refreshPage() {
      this.reload();
    },
    returnIndex() {
      this.$router.push(
        {
          path: this.indexRouter
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@include b(error) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: $bgWhite;
  @include e(title) {
    color: $fc3;
    font-weight: bold;
    font-size: 36px;
    line-height: 1;
  }
  @include e(info) {
    margin-top: 20px;
    color: $fc6;
    font-size: 16px;
    line-height: 1;
  }
  @include e(refresh) {
    margin-top: 30px;
    color: #ff4f4f;
    cursor: pointer;
    line-height: 16px;
    .cp-icon {
      margin-right: 5px;
    }
    .text {
      font-size: 16px;
    }
  }
  .el-button--medium {
    margin-top: 32px;
    padding: 14px 46px;
    background-color: #ff4f4f;
    border-color: #ff4f4f;
    &:hover {
      opacity: .9;
    }
  }
}
</style>
